import {isEmpty} from 'lodash-es';

export async function useServerApi<T>(url: string, stateKey: string): Promise<T> {
    const data: any = useState(stateKey, () => null);

    if (!isEmpty(data.value)) {
        return data;
    }

    // Fetch data from your server-side API route
    data.value = await $fetch(url);

    return data;
}